import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DateRangePicker from '@material-ui/lab/DateRangePicker';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Pagination from '../../layout/Pagination';
import { useDebouncedEffect } from 'use-debounced-effect';
import { allOrderUpdate, multipleOrderUpdate, readOrder, updateOrderStatus } from '../../Services/service';
import { endDate, showToastError, showToastSuccess, startDate } from '../../store/constant';
import moment from 'moment'

const ClaimShipmentList = React.memo(({optionList}) => {
    const [value, setValue] = React.useState([startDate, endDate]);
    const [searchText, setSearchText] = useState();
    const [option, setOption] = useState(optionList);
    const [list, setList] = useState([]);
    const [totoalRecords, setTotalRecords] = useState(optionList);
    const [all, setAll]=useState(false)
    const [allValues, setAllValues]= useState({
        website:'',
        currentPage:1,
        limit:50,
    })

    const handle = {
        search: (e) => {
            setSearchText(e.target.value);
        },
        chnage : (e, name) => {
            setAllValues({ ...allValues, [name]: e })
        }
    };

    useDebouncedEffect(() => {
      readData()
    }, 1000, [searchText]);

    useEffect(()=>{
      setOption(optionList)
    },[optionList])

    useEffect(()=>{
       readData();
    },[allValues, value])

    useEffect(()=>{
        if(list?.length > 1 &&list?.every(e=>e?.selected == true))setAll(true);
        else setAll(false);
    },[list])


    const setSeleted =(flag, items= [])=>{
        let order= JSON.parse(JSON?.stringify(items))
        order?.map(e=>e.selected = flag);
        setList(order)
    }

    const selectOrder=(value, id)=>{
        let order= JSON.parse(JSON?.stringify(list))
        if(id && list?.length){
           let i =list?.findIndex(e=>e?.orderId === id)
          if(i>=0){
             order[i].selected = value;
             setList(order)
          }
        }
    }

    const readData =()=>{
      let params ={
         currentPage: allValues?.currentPage,
         limit: allValues?.limit,
         status: 16
      }
      if(allValues?.webSite?.value) params.websiteId = allValues?.webSite?.value
      if(searchText?.length >=3) params.search = searchText
      if(value[0]) params.startDate= value[0]?.valueOf();
      if (value[1]) params.endDate = value[1]?.valueOf();
      if(allValues?.order_method)  params.order_method = allValues?.order_method?.value

      readOrder(params)?.then((res) => {
         if (!res?.data?.error) {
             setList(res?.data?.data?.rows);
             setTotalRecords(res?.data?.data?.count)
         }
         else {
             showToastError(res?.data?.message)
         }
     })
    }

    const updateOrder=(value,id, name)=>{
       let order= JSON.parse(JSON?.stringify(list))
       if(id && list?.length){
          let i =list?.findIndex(e=>e?.orderId === id)
         if(i>=0){

           if(name== 'alter_phone')order[i][name] = value;
           if(name== 'note'&& order[i]?.statusInfo) order[i].statusInfo.note = value;
            order[i].isChange = true;
            setList(order)
         }
       }
    }

 const editOrder=(id)=>{
        let order = list?.find(e=>e?.orderId=== id);
        if(order){
            let item={
                orderId: id,
                status:3,
                alter_phone: order?.alter_phone || '',
                note:  order?.statusInfo.note || '',
            }
            updateOrderStatus(item)?.then((res) => {
                if (!res?.data?.error) {
                    readData();
                    showToastSuccess('Updated Succesfully.');
                }
                else {
                    showToastError(res?.data?.message)
                }
            })
        }
    }
 
    const onChangePagination=(data)=>{
            setAllValues({...allValues, currentPage: data.currentPage, limit: data.limit});
        }

    const orderCancel=(id, status)=>{
        let param ={
            orderId: id,
            status:status || 4,
        }
        updateOrderStatus(param)?.then((res) => {
            if (!res?.data?.error) {
                readData();
                showToastSuccess('Updated Succesfully.');
            }
            else {
                showToastError(res?.data?.message)
            }
        })
    }

    const tranferAllOrder=()=>{

        if(all &&  list?.every(e=>e?.selected == true)){
            let params ={}
            if(allValues?.webSite?.value) params.websiteId = allValues?.webSite?.value
            if(searchText?.length >=3) params.search = searchText
            if(value[0]) params.startDate= value[0]?.valueOf();
            if(value[1]) params.endDate= value[1]?.valueOf();
            params.oldStatus = 3;
            params.status = 4; 
            
           allOrderUpdate(params)?.then((res) => {
               if (!res?.data?.error) {
                   readData();
                   showToastSuccess('Updated Succesfully.');
               }
               else {
                   showToastError(res?.data?.message)
               }
           })
        } 
        else if(!all && list?.some(e=>e?.selected == true)){
            let data={orderIds:[]};
            data.oldStatus = 3;
            data.status = 4; 
            list?.map(_=>{
                if(_?.selected){
                    data?.orderIds?.push(_?.orderId)
                }
            })
            multipleOrderUpdate(data)?.then((res) => {
                if (!res?.data?.error) {
                    readData();
                    showToastSuccess('Updated Succesfully.');
                }
                else {
                    showToastError(res?.data?.message)
                }
            })
        }else{
            showToastError('Please select order to transfer.')
        }
    }
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-2">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                            disableFuture
                            label="Advanced keyboard"
                            value={value}
                            onChange={(newValue) => {setValue(newValue)}}
                            formatDate={(date) => moment(date).format('DD-MM-YYYY')}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <input
                                        className="form-control"
                                        ref={startProps.inputRef}
                                        {...startProps.inputProps}
                                        placeholder="Start Date"
                                        
                                    />
                                    <Box sx={{ mx: 1 }}> To </Box>
                                    <input
                                        className="form-control"
                                        ref={endProps.inputRef}
                                        {...endProps.inputProps}
                                        placeholder="End Date"
                                    />
                                </React.Fragment>
                            )}
                        />
                    </LocalizationProvider>
                </div>
                <div className="col-2">
                    <div className="w_filters-serach">
                        <input
                            type="text"
                            className={`form-control ${searchText !== '' ? 'active' : ''}`}
                            placeholder="Search Order Id/ Phone No."
                            value={searchText ? searchText : ''}
                            onChange={handle.search}
                            autoComplete="off"
                        />
                        {searchText !== '' && (
                            <span className="w_delete-icon u_cp" onClick={() => setSearchText('')}>
                                X
                            </span>
                        )}
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-input">
                        <Select
                            className="w_select"
                            placeholder="Select Website"
                            classNamePrefix="w_select"
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e)=>handle?.chnage(e, 'webSite')}
                            name="color"
                            options={option}
                            maxMenuHeight={110}
                        />
                    </div>
                </div>
                {/* <div className="col-2">
                <button className="w-btn w-btn-green w_100" onClick={()=>tranferAllOrder()} >Transfer Done Order</button>
                </div> */}
            </div>

            <div className="row w_mt-10">
                <div className="col-12">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <label className="w_checkbox">
                                        <input type="checkbox" checked = {all} onChange={(e)=>{setAll(e?.target?.checked); setSeleted(e?.target?.checked, list)}}/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </th>
                                    <th>Order Details</th>
                                    <th>Customer Details</th>
                                    <th>Customer Address</th>
                                    <th>Details SKU</th>
                                    <th>Staff Notes</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list?.map((item, i) => {
                                  let skuId =item?.products?.map(e=>{
                                          return `${e?.sku}(${e?.quantity})`
                                    }).join(', ')

                                    return (
                                        <tr key={item?.orderId}>
                                            <td>
                                                <label className="w_checkbox">
                                                <input type="checkbox" checked = {item?.selected} id={item?.orderId+i} onChange={(e)=>selectOrder(e?.target?.checked, item?.orderId)}/>
                                                    <span className="checkmark"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <div className="w_flex w_align-items-center w_mb-5">
                                                    <span className="w-color-blue w_mr-5 flex-shrink">Order ID : </span> {item?.order_number}
                                                </div>
                                                <span className="w_block w_mb-5">{item?.order_date && moment(item?.order_date)?.format('DD MMMM, YY')}</span>
                                                <span className="w_block w_mb-5 w_text-c">{optionList?.find(e=>e?.value == item?.website)?.label}</span>
                                                <span className="w_block table-label blue-label">Return Received</span>
                                            </td>
                                            <td>
                                                <div className="w_flex w_align-items-center w_mb-5">
                                                    <span className="w-color-blue w_mr-5 flex-shrink">Customer : </span> {item?.full_name}
                                                </div>
                                                <div className="w_block w_mb-5">
                                                    <Link to={''}>{item?.phone}</Link>
                                                </div>
                                                <span className="w_block">
                                                    <input
                                                        className="form-control form-control-table"
                                                        Placeholder="Additional phone number"
                                                        value={item?.alter_phone}
                                                        type="number"
                                                        onChange={(e)=>updateOrder(e.target.value,item?.orderId, 'alter_phone')}
                                                    />
                                                </span>
                                            </td>
                                            <td className="w_note">
                                                <span className="w_block w_mb-5">{item?.address}</span>
                                            </td>  
                                            <td>
                                                <span className="w_block w_mb-5">
                                                   Rs. :{item?.order_amount}
                                                </span>
                                                <span className="w_block w_mb-5"> SKU (QTY):</span>
                                                <span className="w_block w_mb-5">  {skuId }  </span>
                                            </td>
                                            <td className="w_note">
                                                <span className="w_block w_mb-5">
                                                    <textarea
                                                        className="form-control form-control-table-textarea"
                                                        Placeholder="Edit Note"
                                                        value={item?.statusInfo?.note}
                                                        maxLength={200}
                                                        onChange={(e)=>updateOrder(e.target.value,item?.orderId, 'note')}
                                                    />
                                                </span>
                                                 <span className="w_block w_mb-5">- {item?.statusInfo?.updatedBy && item?.statusInfo?.updatedBy?.firstName+ ' ' + item?.statusInfo?.updatedBy?.lastName}</span> 
                                            </td>
                                            <td>
                                              {
                                                  item?.isChange && <> <button className="w-table-button w-table-button-white w_mr-15" onClick={()=>editOrder(item?.orderId)}>Update</button> <br /></>
                                              } 
                                                <input className="form-control form-control-table w_mb-5" Placeholder="Enter your email" />
                                                <br />
                                               <button className="w-table-button w-table-button-yellow">Claim Shipment</button>
                                               <br />
                                                <button className="w-table-button w-table-button-danger">Delete</button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Pagination onChange={onChangePagination} pageCount={totoalRecords} page={allValues?.currentPage} limit={allValues?.limit}/>
        </React.Fragment>
    );
});


export default ClaimShipmentList;
