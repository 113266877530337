import React, { useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DateRangePicker from '@material-ui/lab/DateRangePicker';
import Select from 'react-select';
import { Link, useHistory, useParams } from 'react-router-dom';
import { allOrderUpdate, exportList, multipleOrderUpdate, readOrder, updateOrders, updateOrderStatus } from '../../Services/service';
import useDebouncedEffect  from 'use-debounced-effect';
import { endDate, showToastError, showToastSuccess, startDate } from '../../store/constant';
import moment from 'moment';
import Pagination from '../../layout/Pagination';
import SideModal from '../components/SideModal';
import { findParentElementFromClassName } from '@material-ui/data-grid';
import { useForceUpdate } from '../../hooks/useForceUpdate';
import SimpleReactValidator from 'simple-react-validator';
import Loader from '../../ui-component/Loader';

const NotReceivedOrder = React.memo(({optionList}) => {
    const forceUpdate = useForceUpdate();
    const validOrder = useRef(new SimpleReactValidator({ element: (message, className) => <div className={'u_error'}>{message}</div> }));
    const {id}=useParams();
    const history = useHistory();
    const [value, setValue] = React.useState([startDate, endDate]);
    const [searchText, setSearchText] = useState();
    const [option, setOption] = useState(optionList);
    const [list, setList] = useState([]);
    const [totoalRecords, setTotalRecords] = useState(optionList);
    const [all, setAll]=useState(false)
    const [editData, setEditData]= useState(null);
    const [isLoader, setLoader] = useState(false);
    const [allValues, setAllValues]= useState({
        website:'',
        currentPage:1,
        limit:50,
    })

    const [open, setOpen] = useState(false); /* Side modal open */

    // Close modal and remove modal from DOM
    const close = () => {
        setOpen(false);
    };

    const handle = {
        search: (e) => {
            setSearchText(e.target.value);
        },
        chnage : (e, name) => {
            setAllValues({ ...allValues, [name]: e })
        }
    };

    useDebouncedEffect(() => {
      readData()
    }, 1000, [searchText]);

    useEffect(()=>{
      setOption(optionList)
    },[optionList])

    useEffect(()=>{
       readData();
    },[allValues, value])

    useEffect(()=>{
        if(list?.length > 1 &&list?.every(e=>e?.selected == true))setAll(true);
        else setAll(false);
    },[list])


    const setSeleted =(flag, items= [])=>{
        let order= JSON.parse(JSON?.stringify(items))
        order?.map(e=>e.selected = flag);
        setList(order)
    }

    const selectOrder=(value, id)=>{
        let order= JSON.parse(JSON?.stringify(list))
        if(id && list?.length){
           let i =list?.findIndex(e=>e?.orderId === id)
          if(i>=0){
             order[i].selected = value;
             setList(order)
          }
        }
    }

    const readData =()=>{
      let params ={
         currentPage: allValues?.currentPage,
         limit: allValues?.limit,
         status:parseInt(id == 5 || id == 20 ? 5 : id== 21 ? 6 : id == 22? 7 : 5)
      }
      if(allValues?.webSite?.value) params.websiteId = allValues?.webSite?.value
      if(searchText?.length >=3) params.search = searchText
      if(value[0]) params.startDate= value[0]?.valueOf();
      if (value[1]) params.endDate = value[1]?.valueOf();
        if(allValues?.order_method)  params.order_method = allValues?.order_method?.value

      readOrder(params)?.then((res) => {
         if (!res?.data?.error) {
             setList(res?.data?.data?.rows);
             setTotalRecords(res?.data?.data?.count)
         }
         else {
             showToastError(res?.data?.message)
         }
     })
    }

    const updateOrder = (value, id, name) => {
        if(validOrder?.current?.allValid() && editData){
            updateOrders(editData)?.then((res) => {
                if (!res?.data?.error) {
                    setOpen(false); 
                    readData();
                    showToastSuccess('Updated Succesfully.');
                }
                else {
                    showToastError(res?.data?.message)
                }
            })
  
        }else{
            validOrder?.current?.showMessages();
            forceUpdate()
        }
    };

    const editChange = (e, name, type, i, status) => {
        let order = JSON.parse(JSON?.stringify(editData));
        let products = order?.products;
        if (!type) {
            setEditData({ ...editData, [name]: e?.target?.value });
        }
        if (type && products?.length > 0) {
            products[i][name] = e?.target?.value;
            setEditData({ ...editData, products });
        }
        if(status && order?.statusInfo){
            order.statusInfo.note = e?.target?.value;
            setEditData({ ...editData, statusInfo: order?.statusInfo });
        }
    };

    const editOrder = (item) => {
        setEditData(item);setOpen(true); 
    };
 
    const onChangePagination=(data)=>{
            setAllValues({...allValues, currentPage: data.currentPage, limit: data.limit});
            let params ={
                currentPage: data?.currentPage,
                limit: data?.limit,
                status:parseInt(id == 5 || id == 20 ? 5 : id== 21 ? 6 : id == 22? 7 : 5)
             }
             if(allValues?.webSite?.value) params.websiteId = allValues?.webSite?.value
             if(searchText?.length >=3) params.search = searchText
             if(value[0]) params.startDate= value[0]?.valueOf();
             if(value[1]) params.endDate= value[1]?.valueOf();
       
             readOrder(params)?.then((res) => {
                if (!res?.data?.error) {
                    setList(res?.data?.data?.rows);
                    setTotalRecords(res?.data?.data?.count)
                }
                else {
                    showToastError(res?.data?.message)
                }
            })
        }

    const orderCancel=(oId, status)=>{
        let param ={
            orderId: oId,
            status:status || 4,
        }
        updateOrderStatus(param)?.then((res) => {
            if (!res?.data?.error) {
                readData();
                showToastSuccess('Updated Succesfully.');
            }
            else {
                showToastError(res?.data?.message)
            }
        })
    }

    const orderTransfer=(oId, status)=>{
        let param ={
            orderId: oId,
            status:parseInt(id == 5 || id == 20 ? 6 : id== 21 ? 7 : 8),
        }
        updateOrderStatus(param)?.then((res) => {
            if (!res?.data?.error) {
                readData();
                showToastSuccess('Updated Succesfully.');
            }
            else {
                showToastError(res?.data?.message)
            }
        })
    }

    const reDirect =(oId)=>{
        history?.push('/orders/all-order/'+ oId)
    }

    const tranferAllOrder=(next)=>{

        if(all &&  list?.every(e=>e?.selected == true)){
            let params ={}
            if(allValues?.webSite?.value) params.websiteId = allValues?.webSite?.value
            if(searchText?.length >=3) params.search = searchText
            if(value[0]) params.startDate= value[0]?.valueOf();
            if(value[1]) params.endDate= value[1]?.valueOf();
            params.oldStatus = parseInt(id == 5 || id == 20 ? 5 : id== 21 ? 6 : id == 22? 7 : 5);
            if(next) params.status =  parseInt(id == 5 || id == 20 ? 6 : id== 21 ? 7 : id == 22? 7 : 7);
            else  params.status = 4; 
            
           allOrderUpdate(params)?.then((res) => {
               if (!res?.data?.error) {
                   readData();
                   showToastSuccess('Updated Succesfully.');
               }
               else {
                   showToastError(res?.data?.message)
               }
           })
        } 
        else if(!all && list?.some(e=>e?.selected == true)){
            let data={orderIds:[]};
            data.oldStatus = parseInt(id == 5 || id == 20 ? 5 : id== 21 ? 6 : id == 22? 7 : 5);
            if(next) data.status =  parseInt(id == 5 || id == 20 ? 6 : id== 21 ? 7 : id == 22? 7 : 7);
            else  data.status = 4;
            list?.map(_=>{
                if(_?.selected){
                    data?.orderIds?.push(_?.orderId)
                }
            })
            multipleOrderUpdate(data)?.then((res) => {
                if (!res?.data?.error) {
                    readData();
                    showToastSuccess('Updated Succesfully.');
                }
                else {
                    showToastError(res?.data?.message)
                }
            })
        }else{
            showToastError('Please select order to transfer.')
        }
    }

    const exportOrder =()=>{
        setLoader(true);
            let params = {
                status:parseInt(id == 5 || id == 20 ? 5 : id== 21 ? 6 : id == 22? 7 : 5)
            };
              if (searchText?.length >= 3) params.search = searchText;
                if (value[0]) params.startDate = value[0]?.valueOf();
             if (value[1]) params.endDate = value[1]?.valueOf();
             if(allValues?.order_method)  params.order_method = allValues?.order_method?.value;
             if(allValues?.account?.value) params.accountId = allValues?.account?.value;
            if(allValues?.webSite?.value) params.websiteId = allValues?.webSite?.value;

             exportList(params)?.then((res) => {
                if (!res?.data?.error) {
                    setLoader(false);
                    res?.data?.data?.file?.map(e=>{
                        window.open(e, '_self')
                       })
                    showToastSuccess('Exported Succesfully.');
                } else {
                    setLoader(false);
                    showToastError(res?.data?.data?.error || res?.data?.message);
                }
            });
    }

    return (
        <React.Fragment>
             {isLoader && <Loader />}
              <div className="row">
                <div className="col-2 w_mb-15">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                            disableFuture
                            label="Advanced keyboard"
                            value={value}
                            onChange={(newValue) => {setValue(newValue)}}
                            formatDate={(date) => moment(date).format('DD-MM-YYYY')}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <input
                                        className="form-control"
                                        ref={startProps.inputRef}
                                        {...startProps.inputProps}
                                        placeholder="Start Date"
                                        
                                    />
                                    <Box sx={{ mx: 1 }}> To </Box>
                                    <input
                                        className="form-control"
                                        ref={endProps.inputRef}
                                        {...endProps.inputProps}
                                        placeholder="End Date"
                                    />
                                </React.Fragment>
                            )}
                        />
                    </LocalizationProvider>
                </div>
                <div className="col-2">
                    <div className="w_filters-serach">
                        <input
                            type="text"
                            className={`form-control ${searchText !== '' ? 'active' : ''}`}
                            placeholder="Search Order Id/ Phone No."
                            value={searchText ? searchText : ''}
                            onChange={handle.search}
                            autoComplete="off"
                        />
                        {searchText !== '' && (
                            <span className="w_delete-icon u_cp" onClick={() => setSearchText('')}>
                                X
                            </span>
                        )}
                    </div>
                </div>
                <div className="col-2">
                <div className="w_flex w_align-center w_100">
                        <div className="form-input w_100">
                        <Select
                            className="w_select"
                            placeholder="Select Website"
                            classNamePrefix="w_select"
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e)=>handle?.chnage(e, 'webSite')}
                            name="color"
                            options={option}
                            maxMenuHeight={110}
                        />
                    </div>
                    <div className="w_export-icon" onClick={() => exportOrder()}>
                            <img src="/img/export.svg" />
                        </div>
                    </div>
                    
                </div>
                <div className="col-2">
                <button className="w-btn w-btn-green w_100" onClick={()=>tranferAllOrder()}>Transfer Done Order</button>
                </div>
            </div>
            <div className="row">
                <div className="col-2">
                    <button className={`w-btn w-btn-blue-only w_100 ${id == 5 || id == 20 ? ' w-active' :''}`} onClick={()=>reDirect(5)}>1 Call</button>
                </div>
                <div className="col-2">
                    <button className={`w-btn w-btn-blue-only w_100 ${id == 21 ? ' w-active' :''}`}  onClick={()=>reDirect(21)}>2 Call</button>
                </div>
                <div className="col-2">
                    <button className={`w-btn w-btn-blue-only w_100 ${id == 22 ? ' w-active' :''}`}  onClick={()=>reDirect(22)}>3 Call</button>
                </div>
                <div className="col-2">
                   {id != 22 && <button className={`w-btn w-btn-green w_100`}  onClick={()=>tranferAllOrder(true)} >Transfer Next step</button>}
                </div>
            </div> 
            <div className='row w_mt-15'>  
            <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Paid/COD:</label>
                    <div className="form-input">
                        <Select
                            className="w_select"
                            placeholder="Select Filter"
                            classNamePrefix="w_select"
                            value={allValues?.order_method}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e) => handle?.chnage(e, 'order_method')}
                            name="color"
                            options={[{label: 'PREPAID', value: 2}, {label: 'COD', value: 1}]}
                            maxMenuHeight={110}
                        />
                    </div>
                </div>
                 </div>       
            <div className="row w_mt-10">
                <div className="col-12">
                <span>{totoalRecords || 0} Records</span>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <label className="w_checkbox">
                                        <input type="checkbox" checked = {all} onChange={(e)=>{setAll(e?.target?.checked); setSeleted(e?.target?.checked, list)}}/>

                                            <span className="checkmark"></span>
                                        </label>
                                    </th>
                                    <th>Order Details</th>
                                    <th>Customer Details</th>
                                    <th>Customer Address</th>
                                    <th>Details SKU</th>
                                    <th>Staff Notes</th>
                                    <th>Action</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {list?.map((item, i) => {
                                  let skuId =item?.products?.map(e=>{
                                          return `${e?.sku}(${e?.quantity})`
                                    }).join(', ')

                                    return (
                                        <tr key={item?.orderId}>
                                            <td>
                                                <label className="w_checkbox">
                                                <input type="checkbox" checked = {item?.selected} id={item?.orderId+i} onChange={(e)=>selectOrder(e?.target?.checked, item?.orderId)}/>

                                                    <span className="checkmark"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <div className="w_flex w_align-items-center w_mb-5 w_cp">
                                                    <span className="w-color-blue w_mr-5 flex-shrink">Order ID : </span> {item?.order_number}
                                                </div>
                                                <span className="w_block w_mb-5">{item?.order_date && moment(item?.order_date)?.format('DD MMMM, YY')}</span>
                                                <span className="w_block w_mb-5 w_text-c">{optionList?.find(e=>e?.value == item?.website)?.label}</span>
                                                <span className="w_block w_mb-5 w_text-c">
                                                    {item?.state} - {item?.zipcode}
                                                </span>
                                            </td>
                                            <td>
                                                <div className="w_flex w_align-items-center w_mb-5">
                                                    <span className="w-color-blue w_mr-5 flex-shrink">Customer : </span> {item?.full_name}
                                                </div>
                                                <div className="w_block w_mb-5">
                                                    <Link to={''}>{item?.phone}</Link>
                                                </div>
                                                <span className="w_block">
                                                {item?.alter_phone}
                                                </span>
                                            </td>
                                            <td className="w_note">
                                                <span className="w_block w_mb-5">{item?.address}</span>
                                            </td>  
                                            <td>
                                                <span className="w_block w_mb-5">
                                                   Rs. :{item?.order_amount}
                                                </span>
                                                <span className="w_block w_mb-5"> SKU (QTY):</span>
                                                <span className="w_block w_mb-5">  {skuId }  </span>
                                                {
                                                   <span className="w_block table-label yellow-label">{item?.order_method == 1? 'COD' : 'PREPAID'}</span>
                                                }
                                            </td>
                                            <td className="w_note">
                                                <span className="w_block w_mb-5">
                                                {item?.statusInfo?.note}
                                                </span>
                                                 <span className="w_block w_mb-5">
                                                { item?.statusInfo?.updatedBy?.firstName && <span className="w_block w_mb-5">-by: {item?.statusInfo?.updatedBy && item?.statusInfo?.updatedBy?.firstName+ ' ' + item?.statusInfo?.updatedBy?.lastName}</span>} 
                                                {item?.statusInfo?.updatedAt &&  moment(item?.statusInfo?.updatedAt).format('DD MMM YYYY, hh:mm A')}

                                                     </span> 
                                            </td>
                                            <td>
                                            <button className="w-table-button w-table-button-white w_mr-15" onClick={()=>editOrder(item)}>Edit</button> <br />

                                              {
                                                id != 22 &&    <>
                                                    <button className="w-table-button w-table-button-save w_mr-15" onClick={()=>orderTransfer(item?.orderId, 4)} >Next Step</button>
                                                     <br />
                                                    </>
                                              }
                                              
                                                <button className="w-table-button w-table-button-save w_mr-15" onClick={()=>orderCancel(item?.orderId, 4)} >Order Done</button>
                                                <br />
                                                 <button className="w-table-button w-table-button-danger" onClick={()=>orderCancel(item?.orderId, 8)}>Order Cancel</button> 
                                                {/* <br />
                                                <button className="w-table-button w-table-button-yellow" onClick={()=>orderCancel(item?.orderId, 5)} >Not Received</button> */}
                                            </td>
                                            <td>
                                                <a href={'https://wa.me/91' +  item?.phone || item?.alter_phone} target='_blank'>
                                                    <img src="/img/wp.svg" />
                                                </a>
                                                </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Pagination onChange={onChangePagination} pageCount={totoalRecords} page={allValues?.currentPage} limit={allValues?.limit}/>
        
        
        {/* Side modal */}
        {open && (
               <SideModal
               open={open}
               name={'Update Order'}
               maxWidth={900}
               onClose={() => close()}
               header={<h4>Update Order</h4>}
               footer={
                   <div className="row w_pl-20 w_pr-20">
                       <div className="u_col-3">
                           <button className={`w-btn w-btn-green`} onClick ={()=> updateOrder()}>Save</button>
                       </div>
                       <div className="u_col-3">
                           <button className={`w-btn w-btn-danger w_ml-20`} onClick={() => close()}>
                               Cancel
                           </button>
                       </div>
                       <div className="u_col-6"></div>
                   </div>
               }
           >
               <div className="w_pl-20 w_pt-20 w_pb-20 w_pr-20">
                   <div className="row">
                       <div className="col-lg-4 col-md-6 w_mb-20">
                           <label className="w_label">Order ID</label>
                           <div className="form-input">
                               <input type="text" onChange={(e)=>editChange(e, 'order_number', )} value={editData?.order_number} placeholder="Order ID" className="form-control" />
                           </div>
                           <span className="w_error">{validOrder?.current?.message('order_number', editData?.order_number, 'required')}</span>
                       </div>
                       <div className="col-lg-4 col-md-6 w_mb-20">
                           <label className="w_label">Customer Name</label>
                           <div className="form-input">
                               <input type="text" onChange={(e)=>editChange(e, 'full_name', )} value={editData?.full_name} placeholder="Customer Name" className="form-control" />
                           </div>
                       </div>
                       <div className="col-lg-4 col-md-6 w_mb-20">
                                <label className="w_label">Staff Note</label>
                                <textarea
                                    value={editData?.statusInfo?.note}
                                    onChange={(e) => editChange(e, 'note', null, null, true)}
                                    placeholder="Customer Address"
                                    className="form-control form-control-table-textarea"
                                />
                            </div>
                    
                   </div>
                   <div className="row">
                   <div className="col-lg-4 col-md-6 w_mb-20">
                           <label className="w_label">State</label>
                           <div className="form-input">
                               <input type="string" onChange={(e)=>editChange(e, 'state', )}  value={editData?.state} placeholder="State" className="form-control" />
                           </div>
                       </div>
                       <div className="col-lg-4 col-md-6 w_mb-20">
                           <label className="w_label">Pincode</label>
                           <div className="form-input">
                               <input type="number"  onChange={(e)=>editChange(e, 'zipcode', )}  value={editData?.zipcode} placeholder="zipcode" className="form-control" />
                           </div>
                       </div>
                       <div className="col-lg-4 col-md-6 w_mb-20">
                           <label className="w_label">City</label>
                           <div className="form-input">
                               <input type="string"  onChange={(e)=>editChange(e, 'city', )}  value={editData?.city} placeholder="city" className="form-control" />
                           </div>
                       </div>
                   </div>
                   <div className="row">
                   <div className="col-lg-4 col-md-6 w_mb-20">
                           <label className="w_label">Mobile Number</label>
                           <div className="form-input">
                               <input type="number" onChange={(e)=>editChange(e, 'phone', )}  value={editData?.phone} placeholder="Mobile Number" className="form-control" />
                           </div>
                       </div>
                       <div className="col-lg-4 col-md-6 w_mb-20">
                           <label className="w_label">Other Number</label>
                           <div className="form-input">
                               <input type="number"  onChange={(e)=>editChange(e, 'alter_phone', )}  value={editData?.alter_phone} placeholder="Other Number" className="form-control" />
                           </div>
                       </div>
                       <div className="col-lg-4 col-md-6 w_mb-20">
                           <label className="w_label">Customer Address</label>
                           <textarea value={editData?.address}  onChange={(e)=>editChange(e, 'address', )}  placeholder="Customer Address" className="form-control form-control-table-textarea" />
                       </div>
                      
                   </div>
                   {
                       editData?.products?.map((e, i)=>{
                           return (
                               <div className="row">
                  
                               <div className="col-lg-4 col-md-6 w_mb-20">
                                   <label className="w_label">SKU Number</label>
                                   <div className="form-input">
                                       <input type="text" onChange={(e)=>editChange(e, 'sku', true, i )} value={e?.sku} placeholder="SKU Number" className="form-control" />
                                   </div>
                               </div>
                               <div className="col-lg-4 col-md-6 w_mb-20">
                                   <label className="w_label">Product QTY</label>
                                   <div className="form-input">
                                       <input type="number" onChange={(e)=>editChange(e, 'quantity', true, i )} value={e?.quantity} placeholder="Product QTY" className="form-control" />
                                   </div>
                               </div>
                               <div className="col-lg-4 col-md-6 w_mb-20">
                                   <label className="w_label">Price</label>
                                   <div className="form-input">
                                       <input type="number" value={e?.price} onChange={(e)=>editChange(e, 'price', true, i )} placeholder="Price" className="form-control" />
                                   </div>
                               </div>
                           </div>
                           )
                       })
                   }
               
               </div>
           </SideModal>
            )}
        </React.Fragment>
    );
});
export default NotReceivedOrder;
