import React, { useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DateRangePicker from '@material-ui/lab/DateRangePicker';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Pagination from '../../layout/Pagination';
import { endDate, pickerURL, shipRocketURL, showToastError, showToastSuccess, startDate } from '../../store/constant';
import { allOrderUpdate, exportList, multipleOrderUpdate, printSlip, readOrder, updateOrderStatus } from '../../Services/service';
import { useDebouncedEffect } from 'use-debounced-effect';
import moment from 'moment'
import { useForceUpdate } from '../../hooks/useForceUpdate';
import SimpleReactValidator from 'simple-react-validator';
import Loader from '../../ui-component/Loader';

const Returned = React.memo(({optionList, accounts}) => {
    const forceUpdate = useForceUpdate();
    const validator = useRef(new SimpleReactValidator({ element: (message, className) => <div className={'u_error'}>{message}</div> }));
    const [value, setValue] = React.useState([startDate, endDate]);
    const [searchText, setSearchText] = useState();
    const [option, setOption] = useState(optionList);
    const [list, setList] = useState([]);
    const [totoalRecords, setTotalRecords] = useState(optionList);
    const [all, setAll]=useState(false)
    const [isLoader, setLoader]= useState(false);
    const [allValues, setAllValues]= useState({
        website:'',
        currentPage:1,
        limit:50,
    })

    const handle = {
        search: (e) => {
            setSearchText(e.target.value);
        },
        chnage : (e, name) => {
            setAllValues({ ...allValues, [name]: e })
        }
    };

    useDebouncedEffect(() => {
      readData()
    }, 1000, [searchText]);

    useEffect(()=>{
      setOption(optionList)
    },[optionList])

    useEffect(()=>{
       readData();
    },[allValues, value])

    useEffect(()=>{
        if(list?.length > 1 &&list?.every(e=>e?.selected == true))setAll(true);
        else setAll(false);
    },[list])

    const readData =()=>{
      let params ={
         currentPage: allValues?.currentPage,
         limit: allValues?.limit,
         status: 12
      }
      if(allValues?.webSite?.value) params.websiteId = allValues?.webSite?.value
      if(searchText?.length >=3) params.search = searchText
      if(value[0]) params.startDate= value[0]?.valueOf();
      if (value[1]) params.endDate = value[1]?.valueOf();
      if(allValues?.order_method)  params.order_method = allValues?.order_method?.value
      readOrder(params)?.then((res) => {
         if (!res?.data?.error) {
             setList(res?.data?.data?.rows);
             setTotalRecords(res?.data?.data?.count)
         }
         else {
             showToastError(res?.data?.message)
         }
     })
    }


    const onChangePagination=(data)=>{
            setAllValues({...allValues, currentPage: data.currentPage, limit: data.limit});
            let params ={
                currentPage: data?.currentPage,
                limit: data?.limit,
                status: 12
             }
             if(allValues?.webSite?.value) params.websiteId = allValues?.webSite?.value
             if(searchText?.length >=3) params.search = searchText
             if(value[0]) params.startDate= value[0]?.valueOf();
             if(value[1]) params.endDate= value[1]?.valueOf();
       
             readOrder(params)?.then((res) => {
                if (!res?.data?.error) {
                    setList(res?.data?.data?.rows);
                    setTotalRecords(res?.data?.data?.count)
                }
                else {
                    showToastError(res?.data?.message)
                }
            })
        }

    const orderCancel=(id, status)=>{
        let param ={
            orderId: id,
            status:status || 4,
        }
        updateOrderStatus(param)?.then((res) => {
            if (!res?.data?.error) {
                readData();
                showToastSuccess('Updated Succesfully.');
            }
            else {
                showToastError(res?.data?.message)
            }
        })
    }

    const setSeleted =(flag, items= [])=>{
        let order= JSON.parse(JSON?.stringify(items))
        order?.map(e=>e.selected = flag);
        setList(order)
    }

    const selectOrder=(value, id)=>{
        let order= JSON.parse(JSON?.stringify(list))
        if(id && list?.length){
           let i =list?.findIndex(e=>e?.orderId === id)
          if(i>=0){
             order[i].selected = value;
             setList(order)
          }
        }
    }

    const printSingle =(flagNew, ids)=>{
        setLoader(true);
        let data={orderIds:[]};
        data.websiteId=ids?.website
        if(ids) data?.orderIds?.push(ids?.orderId)
        printSlip(data)?.then((res) => {
            if (!res?.data?.error) {
              res?.data?.data?.file?.map(e=>{
               fetch(e).then(function(t) {
                return t.blob().then((b)=>{
                    var a = document.createElement("a");
                    a.href = URL.createObjectURL(b);
                    a.setAttribute("download", new Date()?.valueOf()?.toString());
                    a.click();
                });
            })
               })
                setLoader(false);
                showToastSuccess('Download Succesfully.');
            }
            else {
                setLoader(false);
                showToastError(res?.data?.message)
            }
        })
    }
    
        const tranferAllOrder=()=>{
    
            if(validator?.current?.allValid()){
                setLoader(true);
                if(all &&  list?.every(e=>e?.selected == true)){
                    let params ={}
                    if(allValues?.webSite?.value) params.websiteId = allValues?.webSite?.value
                    if(searchText?.length >=3) params.search = searchText
                    if(value[0]) params.startDate= value[0]?.valueOf();
                    if(value[1]) params.endDate= value[1]?.valueOf();
                    printSlip(params)?.then((res) => {
                       if (!res?.data?.error) {
                          // readData();
                           setLoader(false);
                           res?.data?.data?.file?.map(e=>{
                            window.open(e, '_blank')
                           })
                           showToastSuccess('Download Succesfully.');
                       }
                       else {
                        setLoader(false);
                           showToastError(res?.data?.message)
                       }
                   })
                } 
                else if(!all && list?.some(e=>e?.selected == true)){
                    let data={orderIds:[]};
                    data.websiteId = allValues?.webSite?.value
                    list?.map(_=>{
                        if(_?.selected){
                            data?.orderIds?.push(_?.orderId)
                        }
                    })
                    printSlip(data)?.then((res) => {
                        if (!res?.data?.error) {
                          //  readData();
                          res?.data?.data?.file?.map(e=>{
                           //  window.open(e, '_blank');
                           fetch(e).then(function(t) {
                            return t.blob().then((b)=>{
                                var a = document.createElement("a");
                                a.href = URL.createObjectURL(b);
                                a.setAttribute("download", new Date()?.valueOf()?.toString());
                                a.click();
                            });
                        })
                           })
                            setLoader(false);
                            showToastSuccess('Download Succesfully.');
                        }
                        else {
                            setLoader(false);
                            showToastError(res?.data?.message)
                        }
                    })
                }else{
                    setLoader(false);
                    showToastError('Please select order to Print Slip.')
                }
            }else{
                validator?.current?.showMessages();
                forceUpdate();
            }
        }
        const trackOrder =(item)=>{
            if(item?.shippingInfo?.shipWith === 1){
                window?.open(pickerURL+item?.awb, '_blank')
            }
            if(item?.shippingInfo?.shipWith === 2){
                window?.open(shipRocketURL, '_blank')
            }
        }
        const exportOrder =()=>{
            setLoader(true);
                let params = {
                    status: 12
                };
                  if (searchText?.length >= 3) params.search = searchText;
                 if (value[0]) params.startDate = value[0]?.valueOf();
                 if (value[1]) params.endDate = value[1]?.valueOf();
             if(allValues?.order_method)  params.order_method = allValues?.order_method?.value;
                 if(allValues?.account?.value) params.accountId = allValues?.account?.value;
            if(allValues?.webSite?.value) params.websiteId = allValues?.webSite?.value;
    
                 exportList(params)?.then((res) => {
                    if (!res?.data?.error) {
                        setLoader(false);
                        res?.data?.data?.file?.map(e=>{
                            window.open(e, '_self')
                           })
                        showToastSuccess('Exported Succesfully.');
                    } else {
                        setLoader(false);
                        showToastError(res?.data?.data?.error || res?.data?.message);
                    }
                });
        }
    return (
        <React.Fragment>
            {isLoader &&  <Loader/>}
            <div className="row">
            <div className="col-2">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                            disableFuture
                            label="Advanced keyboard"
                            value={value}
                            onChange={(newValue) => {setValue(newValue)}}
                            formatDate={(date) => moment(date).format('DD-MM-YYYY')}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <input
                                        className="form-control"
                                        ref={startProps.inputRef}
                                        {...startProps.inputProps}
                                        placeholder="Start Date"
                                        
                                    />
                                    <Box sx={{ mx: 1 }}> To </Box>
                                    <input
                                        className="form-control"
                                        ref={endProps.inputRef}
                                        {...endProps.inputProps}
                                        placeholder="End Date"
                                    />
                                </React.Fragment>
                            )}
                        />
                    </LocalizationProvider>
                </div>
                <div className="col-2">
                    <div className="w_filters-serach">
                        <input
                            type="text"
                            className={`form-control ${searchText !== '' ? 'active' : ''}`}
                            placeholder="Search Order Id/ Phone No."
                            value={searchText ? searchText : ''}
                            onChange={handle.search}
                            autoComplete="off"
                        />
                        {searchText !== '' && (
                            <span className="w_delete-icon u_cp" onClick={() => setSearchText('')}>
                                X
                            </span>
                        )}
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-input">
                        <Select
                            className="w_select"
                            placeholder="Select Website"
                            classNamePrefix="w_select"
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e)=>handle?.chnage(e, 'webSite')}
                            value={allValues?.webSite}
                            name="color"
                            options={option}
                            maxMenuHeight={110}
                        />
                         <span className="w_error">{validator?.current?.message('website', allValues?.webSite, 'required')}</span>
                    </div>
                </div>
                <div className="col-2">
                    <div className="w_flex w_align-center w_100">
                        {/* <div className="form-input w_100">
                            <Select
                                className="w_select"
                                placeholder="Select Courier Name"
                                classNamePrefix="w_select"
                                isClearable={true}
                                isSearchable={true}
                                name="color"
                                options={accounts}
                                maxMenuHeight={110}
                            />
                        </div> */}
                        <div className="w_print-icon" onClick={()=>tranferAllOrder()} >
                            <img src="/img/print.svg" />
                        </div>
                        <div className="w_export-icon" onClick={() => exportOrder()}>
                            <img src="/img/export.svg" />
                        </div>
                        <div className="w_100" style={{margin: '0px 10px'}}>
                            <div className="form-input">
                                <Select
                                    className="w_select"
                                    placeholder="Select Paid/COD"
                                    classNamePrefix="w_select"
                                    value={allValues?.order_method}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(e) => handle?.chnage(e, 'order_method')}
                                    name="color"
                                    options={[{label: 'PREPAID', value: 2}, {label: 'COD', value: 1}]}
                                    maxMenuHeight={110}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

            <div className="row w_mt-10">
                <div className="col-12">
                <span>{totoalRecords || 0} Records</span>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                <th>
                                        <label className="w_checkbox">
                                        <input type="checkbox" checked = {all} onChange={(e)=>{setAll(e?.target?.checked); setSeleted(e?.target?.checked, list)}}/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </th>
                                    <th>Order Details</th>
                                    <th>Product Details</th>
                                    <th>Customer Address</th>
                                    <th>
                                        <div className="w_flex w_align-center">
                                            Details SKU
                                            {/* <a className="w_sorting">
                                                <img src="/img/sorting.svg" />
                                            </a> */}
                                        </div>
                                    </th>
                                    <th>Courier Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list?.map((item, i) => {
                                    let skuId =item?.products?.map(e=>{
                                        return `${e?.sku}(${e?.quantity})`
                                  }).join(', ')
                                    return (
                                        <tr>
                                             <td>
                                                <label className="w_checkbox">
                                                <input type="checkbox" checked = {item?.selected} id={item?.orderId+i} onChange={(e)=>selectOrder(e?.target?.checked, item?.orderId)}/>
                                                    <span className="checkmark"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <div className="w_flex w_align-items-center w_mb-5">
                                                    <span className="w-color-blue w_mr-5 flex-shrink">Order ID : </span> {item?.order_number}
                                                </div>
                                                <div className="w_flex w_align-items-center w_mb-5">
                                                    <span className="w-color-blue w_mr-5 flex-shrink">Tracking ID : </span> {item?.awb}
                                                </div>
                                                <span className="w_block w_mb-5 w_text-c">{moment(item?.order_date)?.format('DD/MM/YYYY')}</span>
                                                <span className="w_block w_mb-5 w_text-c">{optionList?.find(_=>_?.value === item?.website)?.label}</span>
                                                <span className="w_block table-label red-label">Returned</span>
                                            </td>
                                            <td>
                                                <div className="w_flex w_align-items-center w_mb-5">
                                                    <span className="w-color-blue w_mr-5 flex-shrink">Total Price : </span> {item?.order_amount}
                                                </div>
                                                <span className="w_block w_mb-5">{item?.products?.map(e=>{
                                                    return e?.item
                                                })?.join(', ')}</span>
                                                {
                                                  <span className="w_block table-label green-label">{(item?.paid || item?.order_method == 2) ? 'PAID' : 'UNPAID'}</span>
                                                }
                                                 {
                                                   <span className="w_block table-label yellow-label">{item?.order_method == 1? 'COD' : 'PREPAID'}</span>
                                                }
                                            </td>
                                            <td className="w_address">
                                                <span className="w_block w_mb-5">{item?.address}</span>
                                            </td>
                                            <td>
                                                <div className="w_flex w_align-items-center w_mb-5">
                                                    <span className="w-color-blue w_mr-5 flex-shrink">SKU(QTY) : </span> {skuId}
                                                </div>
                                            </td>
                                            <td>
                                                <span className="w_block w_mb-5">{item?.courier_name}</span>
                                            </td>
                                            <td>
                                                <button className="w-table-button w-table-button-yellow" onClick={()=>printSingle(true, item)}>Print Slip</button>
                                                <br />
                                                <button className="w-table-button w-table-button-green" onClick={()=> trackOrder(item)}>Track Order</button>
                                                <br />
                                                <button className="w-table-button w-table-button-danger" onClick={()=>orderCancel(item?.orderId, 8)}>Cancel Order</button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Pagination onChange={onChangePagination} pageCount={totoalRecords} page={allValues?.currentPage} limit={allValues?.limit}/>
        </React.Fragment>
    );
});

export default Returned;
